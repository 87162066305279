<template>
  <div :style="`font-family:${font},sans-serif`">
    <div>
      <!-- <div>
        <p>{{ this.$route.params.id.id }}</p>
      </div> -->
      <!-- {{this.rows}} -->
      <div class="card p-1">
        <div class="col-md-12 col-xl-12">
          <div>
            <div class="row justify-content-between">
              <div class="col-4 mt-1" style="text-align: start">
                <b-button variant="danger" @click="$router.push({ name: 'seakuruplun' })"> กลับ </b-button>
                <!-- <b-dropdown
                  id="dropdown-1"
                  text="เลือกปีงบประมาณ"
                  variant="primary ml-1"
                >
                  <div v-if="a_lit === null">
                    <b-dropdown-item @click="get_li(b_lit)">
                      {{ b_lit }}</b-dropdown-item
                    >
                  </div>
                  <div v-else>
                    <b-dropdown-item @click="get_li(a_lit)">
                      {{ a_lit }}</b-dropdown-item
                    >
                    <b-dropdown-item @click="get_li(b_lit)">
                      {{ b_lit }}</b-dropdown-item
                    >
                  </div>
                </b-dropdown> -->
              </div>
              <div class="col-4 mt-1" style="text-align: center">
                <span class="h2" style="color: #558cef">ทะเบียนคุมทรัพย์สิน</span>
              </div>

              <div class="col-4" style="text-align: end">
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary">
                        <feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50" />
                      </b-button>
                    </template>
                    <!-- <b-dropdown-item>
                      <feather-icon icon="FileTextIcon" class="mr-50" />
                      
                    </b-dropdown-item> -->
                    <b-dropdown-item @click="exportExcel">
                      <span>
                        <feather-icon icon="FileIcon" class="mr-50" />
                        Export Excel
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="exportpdf">
                      <span>
                        <feather-icon icon="FileIcon" class="mr-50" />
                        Export PDF
                      </span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item>
                      <feather-icon icon="ClipboardIcon" class="mr-50" />
                      <span>Pdf</span>
                    </b-dropdown-item> -->
                  </b-dropdown>
                </div>
              </div>
            </div>

            <hr />
            <div class="row justify-content-start">
              <div class="text-danger">ประเภท</div>
              <span class="ml-1 mr-1">{{ this.$route.params.id.id.e_name }}</span>
              <div class="text-danger">หมายเลขทรัพย์สิน</div>
              <span class="ml-1 mr-1">{{ this.$route.params.id.id.e_number }}</span>
              <div class="text-danger">รายละเอียด</div>
              <span class="ml-1 mr-1">{{ this.detall.information }}</span>
            </div>
            <div class="row justify-content-start">
              <div class="text-danger">สถานที่ตั้ง/หน่วยงานที่รับผิดชอบ</div>
              <span class="ml-1 mr-1">{{
                this.detall.work_gname2 ? this.detall.work_gname2 : this.detall.work_gname
              }}</span>
              <div class="text-danger">ผู้เบิก</div>
              <span class="ml-1 mr-1">{{
                `${this.detall.withdraw_person ? this.detall.withdraw_person : this.detall.response_person}`
              }}</span>
              <div class="text-danger">ผู้รับผิดฃอบ</div>
              <span class="ml-1 mr-1">{{ this.detall.responsename }}</span>
              <div class="text-danger">สถานที่จัดเก็บ</div>
              <span class="ml-1 mr-1">{{ this.detall.place }}</span>
            </div>
            <div class="row justify-content-start">
              <div class="text-danger">ชื่อผู้ขาย/ผู้รับจ้าง/ผู้บริจาค</div>
              <span class="ml-1 mr-1">{{ this.detall.vender }}</span>
              <div class="text-danger">อ้างอิงสัญญาฯ สัญญาเลขที่</div>
              <span class="ml-1 mr-1">{{ this.detall.important_number }}</span>
              <div class="text-danger">วันที่</div>

              <div v-if="this.detall.important_date_th">
                <span class="ml-1 mr-1"></span>
              </div>
              <div v-else>
                <span class="ml-1 mr-1">{{ this.detall.important_date_th }}</span>
              </div>
              <div class="text-danger">เลขที่ใบส่งของ</div>
              <span class="ml-1 mr-1">{{ this.detall.invoice_number }}</span>
              <div class="text-danger">วันที่</div>
              <div v-if="this.detall.invoice_date_th == undefined || this.detall.invoice_date == null">
                <span class="ml-1 mr-1"></span>
              </div>
              <div v-else>
                <span class="ml-1 mr-1">{{ this.detall.invoice_date_th }}</span>
              </div>
            </div>
            <div class="row justify-content-start">
              <div class="text-danger">ที่อยู่</div>
              <span class="ml-1 mr-1">{{ this.detall.vender_address }}</span>
            </div>
            <div class="row justify-content-start">
              <div class="text-danger">ประเภทเงิน :</div>
              <span class="ml-1 mr-1">{{ this.detall.budget_type }}</span>
            </div>
            <div class="row justify-content-start">
              <div class="text-danger">วิธีการได้มา :</div>
              <span class="ml-1 mr-1">{{ this.detall.supply_by }}</span>
            </div>

            <hr />

            <!-- table -->
            <b-overlay :show="show" rounded="sm">
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: pageLength,
                }"
                responsive
                fixed
              >
                <div slot="emptystate">This will show up when there are no rows</div>
                <template slot="table-row" slot-scope="props">
                  <!-- Column: Name -->
                  <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                    <b-avatar :src="props.row.avatar" class="mx-1" />
                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                  </span>

                  <!-- Column: Status -->
                  <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                      {{ props.row.status }}
                    </b-badge>
                  </span>

                  <!-- Column: Action -->
                  <span v-else-if="props.column.field === 'action'">
                    <span>
                      <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                          <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                        </template>
                        <b-dropdown-item>
                          <feather-icon icon="Edit2Icon" class="mr-50" />
                          <span>Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <feather-icon icon="TrashIcon" class="mr-50" />
                          <span>Delete</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Showing 1 to </span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['5', '10', '15', '25', '50', '100']"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import pdfMake from "pdfmake";
import pdfFonts from "../font/custom-fonts";
import _ from "lodash";

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
dayjs.extend(buddhistEra);

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    VueGoodTable,
  },
  data() {
    return {
      detall: {},
      show: false,
      pageLength: 100,
      dir: false,
      columns: [
        {
          label: "ปีที่",
          field: "rownum",
          width: "60px",
        },

        {
          label: "วัน-เดือน-ปี",
          field: "admitdate_th",
          width: "120px",
        },
        {
          label: "ที่เอกสาร",
          field: "equipment_code",
          width: "120px",
        },
        {
          label: "รายการ",
          field: "information",
          width: "250px",
        },

        {
          label: "ราคาต่อหน่วย",
          field: "unitprice",
          width: "130px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "จำนวนหน่วย",
          field: "unittype",
          width: "120px",
        },
        {
          label: "มูลค่ารวม",
          field: "unitprice",
          width: "130px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        // {
        //   label: 'ราคารวม',
        //   field: 'ราคารวม',
        //   width: '100px',
        // },
        {
          label: "อายุการใช้งาน",
          field: "expire",
          width: "130px",
        },
        {
          label: "จำนวนวัน",
          field: "daycount",
          width: "130px",
        },
        {
          label: "อัตราค่าเสื่อม (%)",
          field: "อัตราค่าเสื่อมราคาต่อปี",
          width: "150px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ค่าเสื่อมประจำปี",
          field: "ค่าเสื่อมราคาประจำปี",
          width: "150px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "ค่าเสื่อมราคาสะสม",
          field: "ค่าเสื่อมราคาสะสม",
          width: "170px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },
        {
          label: "มูลค่าสุทธิ",
          field: "มูลค่าสุทธิ",
          width: "120px",
          type: "number",
          sortable: true,
          formatFn: this.formatFn,
        },

        // {
        //   label: "Action",
        //   field: "action",
        // },
      ],
      rows: [],
      searchTerm: "",
      a_lit: "",
      b_lit: "",
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
    };
  },
  created() {
    const font = localStorage.getItem("font");
    console.log(font);
    this.font = font;
    _.isEmpty(this.$route.params.id) ? this.$router.push({ path: "/find-equipment" }) : true;

    // this.$route.params.id.id
    this.getdata();
    this.get_data_table();
  },
  methods: {
    formatFn: function (value) {
      return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },
    exportpdf() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs; // 2. set vfs pdf font
      pdfMake.fonts = {
        // download default Roboto font from cdnjs.com
        Roboto: {
          normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
          bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
          italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
          bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
        },
        // Kanit Font
        Kanit: {
          // 3. set Kanit font
          normal: "Sarabun-Regular.ttf",
          bold: "Sarabun-Medium.ttf",
          italics: "Sarabun-Italic.ttf",
          bolditalics: "Sarabun-MediumItalic.ttf",
        },
      };
      const docDefinition = {
        pageMargins: [10, 80, 10, 50],
        pageSize: "A4",
        pageOrientation: "landscape",
        info: {
          title: "ทะเบียนคุมทรัพย์สิน",
        },
        header: [
          {
            text: "ทะเบียนคุมทรัพย์สิน",
            alignment: "center",
            margin: [0, 15, 0, 0],
          },
          {
            text: "สถาบันทดสอบทางการศึกษาแห่งชาติ (องค์การมหาชน)",
            alignment: "center",
            margin: [0, 0, 0, 0],
          },
        ],

        content: [
          {
            text: `ประเภท: ${this.detall.e_name} หมายเลขทรัพย์สิน: ${this.detall.e_number} รายละเอียด: ${this.detall.information}`,
            style: "text",
          },
          {
            text: `สถานที่ตั้ง/หน่วยงานที่รับผิดชอบ: ${
              this.detall.work_gname2 ? this.detall.work_gname2 : this.detall.work_gname
            } ผู้เบิก: ${
              this.detall.withdraw_person ? this.detall.withdraw_person : this.detall.response_person
            } ผู้รับผิดฃอบ: ${this.detall.responsename} สถานที่จัดเก็บ: ${this.detall.place}`,
            style: "text",
          },
          {
            text: `ชื่อผู้ขาย/ผู้รับจ้าง/ผู้บริจาค: ${this.detall.vender} อ้างอิงสัญญาฯ สัญญาเลขที่: ${
              this.detall.important_number == null ? " " : this.detall.important_number
            } วันที่: ${this.detall.important_date_th == undefined ? "" : this.detall.important_date_th} `,
            style: "text",
          },
          {
            text: `เลขที่ใบส่งของ: ${this.detall.invoice_number == null ? "" : this.detall.invoice_number} วันที่: ${
              this.detall.invoice_date_th == undefined ? " " : this.detall.invoice_date_th
            } ที่อยู่: ${this.detall.vender_address} ประเภทเงิน: ${this.detall.budget_type} วิธีการได้มา: ${
              this.detall.supply_by
            }`,
            style: "text",
          },
          {
            table: {
              headerRows: 1,
              widths: [
                "auto",
                "auto",
                "auto",
                "*",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
                "auto",
              ],

              body: [
                [
                  {
                    text: `ปีที่`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `วัน-เดือน-ปี`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ที่เอกสาร`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `รายการ`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `จำนวนหน่วย`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ราคาต่อหน่วย`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `มูลค่ารวม`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `อายุการใช้งาน`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `จำนวนวัน`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `อัตราค่าเสื่อม (%)`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ค่าเสื่อมประจำปี`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `ค่าเสื่อมราคาสะสม`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                  {
                    text: `มูลค่าสุทธิ`,
                    style: "tableHeader",
                    alignment: "center",
                  },
                ],
                ...this?.rows.map((res, index) => {
                  return [
                    { text: res.rownum, style: "text_center" },
                    { text: res.admitdate_th, style: "text_center" },
                    { text: res.equipment_code, style: "text_center" },
                    { text: res.information, style: "text_center" },
                    { text: res.unittype, style: "text_center" },
                    {
                      text: `${
                        _.isEmpty(res.unitprice)
                          ? ""
                          : Number(res.unitprice)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                    {
                      text: `${
                        _.isEmpty(res.unitprice)
                          ? ""
                          : Number(res.unitprice)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                    { text: res.expire, style: "text_number" },
                    { text: res.daycount, style: "text_number" },
                    {
                      text: res.อัตราค่าเสื่อมราคาต่อปี,
                      style: "text_number",
                    },
                    {
                      text: `${
                        res.ค่าเสื่อมราคาประจำปี == 0
                          ? ""
                          : Number(res.ค่าเสื่อมราคาประจำปี)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                    {
                      text: `${
                        res.ค่าเสื่อมราคาสะสม == 0
                          ? " "
                          : Number(res.ค่าเสื่อมราคาสะสม)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                    {
                      text: `${
                        res.มูลค่าสุทธิ == 0
                          ? " "
                          : Number(res.มูลค่าสุทธิ)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                      }`,
                      style: "text_number",
                    },
                  ];
                }),
              ],
            },
          },
        ],
        defaultStyle: {
          font: "Kanit",
        },
        styles: {
          tableExample: {
            margin: [0, 0, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 8,
            color: "black",
            alignment: "center",
          },
          header: {
            fontSize: 9,
            bold: true,
            alignment: "center",
          },
          title: {
            bold: true,
            fontSize: 9,
            color: "black",
            margin: [0, 0, 0, 0],
          },
          anotherStyle: {
            italics: true,
            alignment: "center",
          },
          text_center: {
            bold: false,
            fontSize: 7,
          },

          text_number: {
            alignment: "right",
            bold: false,
            fontSize: 7,
          },
          text: {
            fontSize: 9,
          },
        },
      };
      pdfMake.createPdf(docDefinition).download(`ทะเบียนคุมทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`);
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: `ทะเบียนคุมทรัพย์สิน ${dayjs(new Date()).format("DD-MM-BBBB-HHmm")}`,
        columns: [
          {
            title: `ทะเบียนคุมทรัพย์สิน`,
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: `ประเภท : ${this.detall.e_name} หมายเลขทรัพย์สิน: ${this.detall.e_number} รายละเอียด : ${this.detall.information}`,
                headerCellOptions: { textAlign: "" },
                children: [
                  {
                    title: `สถานที่ตั้ง/หน่วยงานที่รับผิดชอบ : ${
                      this.detall.work_gname2 ? this.detall.work_gname2 : this.detall.work_gname
                    } ผู้เบิก: ${
                      this.detall.withdraw_person ? this.detall.withdraw_person : this.detall.response_person
                    } ผู้รับผิดฃอบ : ${this.detall.responsename} สถานที่จัดเก็บ : ${this.detall.place}`,
                    headerCellOptions: { textAlign: "" },
                    children: [
                      {
                        title: `ชื่อผู้ขาย/ผู้รับจ้าง/ผู้บริจาค : ${this.detall.vender} อ้างอิงสัญญาฯ สัญญาเลขที่: ${
                          this.detall.important_number == null ||
                          this.detall.important_number == undefined ||
                          this.detall.important_number == ""
                            ? " "
                            : this.detall.important_number
                        } วันที่ : ${
                          this.detall.important_date_th == undefined || this.detall.important_date_th == null
                            ? " "
                            : this.detall.important_date_th
                        } เลขที่ใบส่งของ : ${
                          this.detall.invoice_number == null || this.detall.invoice_number == undefined
                            ? " "
                            : this.detall.invoice_number
                        } วันที่ : ${
                          this.detall.invoice_date_th == undefined || this.detall.invoice_date_th == null
                            ? " "
                            : this.detall.invoice_date_th
                        }`,
                        headerCellOptions: { textAlign: "" },
                        children: [
                          {
                            title: `ที่อยู่ : ${this.detall.vender_address} `,
                            headerCellOptions: { textAlign: "" },
                            children: [
                              {
                                title: `ประเภทเงิน: ${this.detall.budget_type} วิธีการได้มา : ${this.detall.supply_by}`,
                                headerCellOptions: { textAlign: "" },
                                children: [
                                  {
                                    title: "ปีที่",
                                    field: "rownum",
                                  },
                                  {
                                    title: "วัน-เดือน-ปี",
                                    field: "admitdate_th",
                                  },
                                  {
                                    title: "ที่เอกสาร",
                                    field: "equipment_code",
                                  },
                                  {
                                    title: "รายการ",
                                    field: "information",
                                  },
                                  {
                                    title: "จำนวนหน่วย",
                                    field: "unittype",
                                  },
                                  {
                                    title: "ราคาต่อหน่วย",
                                    field: "unitprice",
                                  },
                                  {
                                    title: "มูลค่ารวม",
                                    field: "unitprice",
                                  },
                                  {
                                    title: "อายุการใช้งาน",
                                    field: "expire",
                                  },
                                  {
                                    title: "จำนวนวัน",
                                    field: "daycount",
                                  },
                                  {
                                    title: "อัตราค่าเสื่อม (%)",
                                    field: "อัตราค่าเสื่อมราคาต่อปี",
                                  },
                                  {
                                    title: "ค่าเสื่อมประจำปี",
                                    field: "ค่าเสื่อมราคาประจำปี_ex",
                                  },
                                  {
                                    title: "ค่าเสื่อมราคาสะสม",
                                    field: "ค่าเสื่อมราคาสะสม_ex",
                                  },
                                  {
                                    title: "มูลค่าสุทธิ",
                                    field: "มูลค่าสุทธิ_ex",
                                  },
                                ],
                              },
                              {
                                title: ``,
                                headerCellOptions: { textAlign: "center" },
                                children: [],
                              },
                            ],
                          },
                          {
                            title: ``,
                            headerCellOptions: { textAlign: "center" },
                            children: [],
                          },
                        ],
                      },
                      {
                        title: ``,
                        headerCellOptions: { textAlign: "center" },
                        children: [],
                      },
                    ],
                  },
                  {
                    title: ``,
                    headerCellOptions: { textAlign: "center" },
                    children: [],
                  },
                ],
              },
              {
                title: ``,
                headerCellOptions: { textAlign: "center" },
                children: [],
              },
            ],
          },
        ],
      });
    },
    async get_li(value) {
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      this.show = true;
      const url = `${API}propertyRegistration?e_number=${this.$route.params.id.id.e_number}&lifetime=${value}`;
      const res = await axios.get(url, head);
      // console.log(res.data.message.data);
      // const listData = res.data.message.data.map(

      //   (element, index) => {
      //     console.log(element)
      //     console.log(index)
      //   }
      // )
      // console.log(listData)

      //this.rows = res.data.message.data;
      this.total = res.data.message.total;
      this.show = false;
    },
    async get_data_table() {
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      this.show = true;
      const url_lifetimeLists = `${API}lifetimeLists?e_number=${this.$route.params.id.id.e_number}`;
      const res_lifetimeLists = await axios.get(url_lifetimeLists, head);
      // console.log(res_lifetimeLists.data.message.data[0]);
      this.a_lit = res_lifetimeLists.data.message.data[0].lifetime_new;
      this.b_lit = res_lifetimeLists.data.message.data[0].lifetime_old;
      var pp = null;
      if (res_lifetimeLists.data.message.data[0].lifetime_new === null) {
        pp = res_lifetimeLists.data.message.data[0].lifetime_old;
      } else {
        pp = res_lifetimeLists.data.message.data[0].lifetime_new;
      }
      const url = `${API}propertyRegistration?e_number=${this.$route.params.id.id.e_number}&lifetime=${pp}&refkey=${this.$route.params.id.id.refkey}`;

      const res = await axios.get(url, head);
      console.log(res.data.message.length);
      this.rows = res.data.message.data.map((element) => {
        return {
          ...element,
          admitdate_th: `${parseInt(element.admitdate.split("-")[2])} ${
            this.month[parseInt(element.admitdate.split("-")[1])]
          } ${Number(element.admitdate.split("-")[0]) + 543}`,
          ค่าเสื่อมราคาประจำปี_ex: Number(element.ค่าเสื่อมราคาประจำปี),
          ค่าเสื่อมราคาสะสม_ex: Number(element.ค่าเสื่อมราคาสะสม),
          มูลค่าสุทธิ_ex: Number(element.มูลค่าสุทธิ),
          unitprice_ex: Number(element.unitprice),
        };
      });

      this.total = res.data.message.data.length;
      this.show = false;
    },
    async getdata() {
      const { access_token } = await this.access_token();
      const url = `${API}headerPropertyRegistration?e_number=${this.$route.params.id.id.e_number}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res);
      this.detall = await res.data.message.data[0];
      console.log([this.detall]);
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
  },
};
</script>

<style></style>
